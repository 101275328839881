const errorMessages = {
    "600": "Invalid IMEI – cannot use this service",
    "601": "Invalid MSISDN – cannot use this service",
    "602": "Invalid USERID – cannot use this service",
    "603": "Invalid akey – have to do authentication again",
    "604": "Invalid password – have to do register again",
    "605": "Invalid IMEI – not match USERID",
    "606": "Invalid MSISDN – not match USERID",
    "607": "IP query msisdn failed!",
    "608": "Multiple registration for devices",
    "609": "Password Expired, over 10 minutes",
    "610": "MSISDN’s Operator not found!",
    "611": "SMS quota not enough!",
    "612": "CLIENT_IN_BLACK",
    "613": "PASSWORD_REQUEST_TOOMUCH",
    "614": "SMS_PASSCODE_FAILED_TOO_MANY_TIMES",
    "615": "WEAK_PASSWORD",
    "616": "MSISDN has been registered",
    "617": "MSISDN has been registered and registered account has joined EA",
    "618": "Token is invalid or expired",
    "619": "INVALID_SESSIONKEY",
    "620": "ABNORMAL_EA_MSISDN",
    "621": "Login Account Locked",
    "622": "NEED_REGISTRATION",
    "623": "INVALID_ACCOUNT_PASSWORD",
    "624": "ACCOUNT_BOUND",
    "625": "INVALID_ACCOUNT",
    "626": "EA_NO_POSSIBLE_MATCH",
    "627": "EA_NO_EXACT_MATCH",
    "628": "WEB_ACCOUNT_LOCKED",
    "635": "UNAUTHORIZED_REGISTRATION",
    "636": "裝置數超過最大上限",
    "637": "Email解綁失敗，因為門號為虛擬門號",
    "638": "多裝置登入失敗，需要重新驗證密碼",
    "640": "登入密碼驗證失敗",
    "641": "登入錯誤次數超過上限，無法再嘗試",
    "642": "無法登入，帳號狀態無效",
    "643": "登入時提供的IMEI與資料不符",
    "644": "超過最大可使用多裝置個數",
    "699": "Please upgrade to the latest version",
    "701": "AS timeout – try it later",
    "705": "MPID already created",
    "706": "MPID not found",
    "801": "Protocol error",
    "901": "System error : I/O error",
    "902": "Invalid parameters",
    "903": "UNAUTHORIZED",
    "999": "System error : Unknown error"
  };
  
  export default errorMessages;
  