import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import Footer from './footer';
import Admin from './components/Admin';
import ProtectedRoute from './components/ProtectedRoute';
import './App.css';
import errorMessages from "./utils/errorMessages";


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <Routes>
      <Route path="/" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
      <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
        <Route path="/admin" element={<Admin />} />
      </Route>
    </Routes>
  );
}

function Login({ setIsAuthenticated }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [token, setToken] = useState(null); // 🔹 定義 token 狀態
  // const [userId, setUserId] = useState(null);


  const navigate = useNavigate();

  // **M+ Flutter 加密 Key & IV**
  const MPLUS_ACCESS_KEY = "D0123P1234LTWMS0M0935P1234LTWMS0"; // 32-byte Key
  const MPLUS_RANDOM_IV = "M0935P1234LTWMS0"; // 16-byte IV

  // **取得加密 Key**
  const getEncryptionKey = () => {
    return CryptoJS.enc.Utf8.parse(MPLUS_ACCESS_KEY);
  };
  

  // const toUrlSafeBase64 = (base64) => {
  //   return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  // };

  // **取得加密 IV**
  const getEncryptionIV = () => {
    return CryptoJS.enc.Utf8.parse(MPLUS_RANDOM_IV);
  };

  // **AES 加密函式**
  const encryptText = async (plainText) => {
    try {
    const key = getEncryptionKey(); // 確保是 32 bytes
    const iv = getEncryptionIV(); // 確保是 16 bytes
    
    const encrypted = CryptoJS.AES.encrypt(plainText, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC, // ✅ 改為 CTR 模式
    padding: CryptoJS.pad.Pkcs7, // ✅ 保持 PKCS7 padding
    });
    
    return encrypted.toString();
    } catch (error) {
    console.error("Encryption error:", error);
    return null;
    }
    };
    
  
  
  

  const getOAuthQueryResponse = async (token, language) => {
    try {
      // **讀取 `belongEald`**
      const belongEald = localStorage.getItem("belongEald") || "";
  
      // **構建請求體**
      const requestBody = {
        token: token, // ✅ 直接使用參數 token
        timestamp: Math.floor(Date.now() / 1000).toString(),
        language: language,
      };
  
      if (belongEald) {
        requestBody.belongEald = belongEald;
      }
  
      console.log("OAuth Query Request Data (Before Encryption):", requestBody);
  
      // **加密請求體**
      const encryptedBody = await encryptText(JSON.stringify(requestBody));
  
      if (!encryptedBody) {
        throw new Error("Encryption failed");
      }
  
      console.log("Encrypted OAuth Query Body:", encryptedBody);
  
      // **發送 POST 請求**
      const response = await axios.post(
        "https://mplusasps.mplusapp.com/AuthenticationServer/OAuthQuery",
        encryptedBody, // 直接傳送加密字串
        {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        }
      );
  
      console.log("OAuth Query Response:", response.data);
  
      // **檢查回應是否成功**
      if (response.data.cause === "0") {
        const userId = response.data.userId;
        console.log("Extracted User ID:", userId);
        // setUserId(userId); // ✅ 存 token 到 state

  
        // ✅ 存到 localStorage，讓其他頁面也能使用
        localStorage.setItem("userId", userId);
      } else {
        console.warn("OAuth Query Failed:", response.data);
      }
  
      return response.data; // 返回 API 回應
    } catch (error) {
      console.error("OAuth Query Error:", error);
      throw error;
    }
  };

  const checkWhitelist = async (userId) => {
    try {
      const whitelistEndpoint = `https://mplus-meet-client-config-service-598671042461.asia-east1.run.app/whitelist/${userId}`;
  
      console.log("Checking Whitelist for User ID:", userId);
  
      const response = await fetch(whitelistEndpoint, {
        method: "GET",
        headers: {
          "Accept": "application/json",
        },
      });
  
      if (response.status === 200) {
        console.log("User is whitelisted.");
        return true;
      } else {
        console.warn("User is not whitelisted. Response status:", response.status);
        return false;
      }
    } catch (error) {
      console.error("Whitelist API Error:", error);
      return false;
    }
  };
  
  
  
  

  // **處理登入邏輯**
  const handleLogin = async () => {
    setError("");
    if (!username || !password) {
      setError("Username and password are required");
      return;
    }
  
    setIsLoading(true);
  
    try {
      // **構建要加密的 JSON**
      const loginData = {
        bindingAccount: username,
        bindingPwd: password,
        loginApName: "android-11.0-1.0.1-gb32-sony",
        timestamp: Math.floor(Date.now() / 1000).toString(),
        language: "en",
      };
  
      console.log("Login Data (Before Encryption):", loginData);
  
      // **進行 AES 加密**
      const encryptedBody = await encryptText(JSON.stringify(loginData));
  
      if (!encryptedBody) {
        throw new Error("Encryption failed");
      }
  
      // **API URL**
      const loginEndpoint =
        "https://mplusasps.mplusapp.com/AuthenticationServer/OAuthAuthentication";
  
      console.log("Sending request to:", loginEndpoint);
      console.log("Encrypted body:", encryptedBody);
  
      // **發送 API 請求**
      const response = await fetch(loginEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
        body: encryptedBody,
      });
  
      const responseData = await response.text();
      console.log("Response:", responseData);
      console.log("Response status:", response.status);
  
      // **檢查 API 回應**
      if (response.status === 200 && responseData) {
        try {
          const parsedData = JSON.parse(responseData);
          console.log("Parsed Response Data:", parsedData);
  
          if (parsedData.cause === "0") {
            console.log("Login successful");
            setIsAuthenticated(true);
            // setToken(parsedData.token); // ✅ 存 token 到 state
  
            // ✅ 調用 OAuth Query API
            const oauthResponse = await getOAuthQueryResponse(parsedData.token, "zh");
  
            if (oauthResponse.cause === "0") {
              const userId = oauthResponse.userId;
              console.log("Extracted User ID:", userId);
              // setUserId(userId);
              localStorage.setItem("userId", userId); // ✅ 存入 localStorage
  
              // ✅ 調用 `whitelist` API
              const isWhitelisted = await checkWhitelist(userId);
  
              if (isWhitelisted) {
                navigate("/admin"); // ✅ 只有當 `whitelist` API 回應 200，才導航
              } else {
                setError("User is not whitelisted");
              }
            } else {
              setError("Failed to get user ID from OAuthQuery");
            }
          } else {
            console.warn("Login failed:", parsedData);
            const errorMessage = errorMessages[parsedData.cause] || `Unknown error (cause: ${parsedData.cause})`;
            setError(`Login failed: ${errorMessage}`);
          }
        } catch (parseError) {
          console.error("Error parsing response JSON:", parseError);
          setError("Invalid response format from server");
        }
      } else {
        console.warn("Login failed:", responseData);
        setError("Invalid credentials or server error");
      }
    } catch (error) {
      console.error("Login error:", error);
  
      if (error.response) {
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        setError(error.response.data?.message || "Server error occurred");
      } else {
        setError("An unexpected error occurred while logging in");
      }
    } finally {
      setIsLoading(false);
    }
  };
  
  
  

  return (
    <div className="App">
      <header className="App-header">
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <p>M+ login (CMS)</p>
        </div>
        <div className={`login-form ${error ? 'error' : ''}`}>
          <div className="form-group">
            <label htmlFor="username" className="label">Username:</label>
            <input
              type="text"
              id="username"
              name="username"
              className="input"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password" className="label">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              className="input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            className="submit-btn"
            onClick={handleLogin}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Login'}
          </button>
          {error && <p className="error-msg">{error}</p>}
        </div>
        <Footer />
      </header>
    </div>
  );
}

export default App;
