import React, { useState } from 'react';
import Sidebar from './Sidebar';
import MeetVersion from './MeetVersion';
import AppConfig from './AppConfig';
import InternalTestUrl from './InternalTestUrl';
import '../styles/admin.css';

const isMobileDevice = /iPhone|iPod|Android/i.test(navigator.userAgent) || 
  (navigator.platform === 'MacIntel' && (navigator.maxTouchPoints > 1 || 'ontouchend' in document));

console.log("Is Mobile Device:", isMobileDevice); // 看看是否偵測到行動裝置
console.log("User Agent:", navigator.userAgent);
console.log("Platform:", navigator.platform);
console.log("Max Touch Points:", navigator.maxTouchPoints);




const Admin = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState('dashboard');

  // 如果是手機裝置，直接顯示 InternalTestUrl，隱藏其他 UI
  if (isMobileDevice) {
    return (
      <div className="admin-container">
        {/* <h1>Internal Test URL</h1> */}
        <InternalTestUrl />
      </div>
    );
  }

  // 桌面版，顯示完整管理介面
  const renderContent = () => {
    switch (selectedMenuItem) {
      case 'meetVersion':
        return <MeetVersion />;
      case 'appConfig':
        return <AppConfig />;
      case 'internalTestUrl':
        return <InternalTestUrl />;
      case 'products':
        return <div className="page-content"><h1>voip推播紀錄</h1><p>這裡是產品頁面的內容。</p></div>;
      case 'settings':
        return <div className="page-content"><h1>設置</h1><p>這裡是設置頁面的內容。</p></div>;
      default:
        return <div className="page-content"><h1>歡迎來到管理員儀表板</h1><p>這裡是管理員儀表板頁面。</p></div>;
    }
  };

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };

  return (
    <div className="admin-container">
      <Sidebar selectedMenuItem={selectedMenuItem} onMenuItemClick={handleMenuItemClick} />
      <div className="content">
        {renderContent()}
      </div>
    </div>
  );
};

export default Admin;
