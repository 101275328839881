import React, { useState, useEffect } from 'react';
import { fetchAppsData } from '../services/apiService';
import '../styles/dropdown.css';
import '../styles/internalTestUrl.css'; // 確保手機版有不同樣式

// 檢測是否為行動裝置
const isMobileDevice =/iPhone|iPod|Android/i.test(navigator.userAgent) || 
  (navigator.platform === 'MacIntel' && (navigator.maxTouchPoints > 1 || 'ontouchend' in document));
const InternalTestUrl = () => {
  const [platform, setPlatform] = useState('ios');
  const [appsData, setAppsData] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async (selectedPlatform) => {
    setError(null);
    if (!selectedPlatform) return;
  
    try {
      const response = await fetchAppsData(selectedPlatform);
      if (response.data?.data) {
        const filteredData = Object.values(
          response.data.data.reduce((acc, app) => {
            acc[app.version] = acc[app.version] || [];
            if (acc[app.version].length < 3) acc[app.version].push(app);
            return acc;
          }, {})
        ).flat();
  
        // 根據 updatedTime 由新到舊排序
        const sortedData = filteredData.sort((a, b) => new Date(b.updatedTime) - new Date(a.updatedTime));
        setAppsData(sortedData);
      } else {
        setAppsData([]);
        setError('未找到相關數據');
      }
    } catch (error) {
      setError('無法取得應用程式資料，請稍後再試');
      console.error('Failed to fetch apps data:', error);
    }
  };
  

  useEffect(() => {
    fetchData('ios');
  }, []);

  return (
    <div className={`internal-test-container ${isMobileDevice ? 'mobile-view' : 'desktop-view'}`}>
      {/* 只有桌面版顯示標題，行動裝置不顯示 */}
      {!isMobileDevice && <h2 className="section-title">內部測試網址</h2>}

      {/* 自訂選單樣式 */}
      <div className="dropdown-wrapper">
        <select
          id="platform-select"
          value={platform}
          onChange={(e) => {
            const selectedPlatform = e.target.value;
            setPlatform(selectedPlatform);
            fetchData(selectedPlatform);
          }}
          className="custom-dropdown"
        >
          <option value="ios">iOS</option>
          <option value="android">Android</option>
        </select>
      </div>

      {error && <p className="error-message">{error}</p>}
      
      {appsData.length > 0 ? (
        <div className="table-container">
          <table className="custom-table">
            <thead>
              <tr>
                <th>版本</th>
                <th>Build</th>
                <th>文件</th>
              </tr>
            </thead>
            <tbody>
              {appsData.map((app, index) => (
                <tr key={index}>
                  <td>{app.version}</td>
                  <td>{app.build}</td>
                  <td>
                    <div className="file-info">
                      <span className="filename">{app.filename}</span>
                      <span className="date">{app.updatedTime}</span>
                    </div>
                  </td>
                  <td>
                    <a href={app.downloadUrl} target="_blank" rel="noopener noreferrer" className="download-button">
                      下載
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        platform && !error && <p className="no-data">暫無數據。</p>
      )}
    </div>
  );
};

export default InternalTestUrl;
