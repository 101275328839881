import React from 'react';

// 檢測是否為行動裝置
const isMobileDevice =/iPhone|iPod|Android/i.test(navigator.userAgent) || 
  (navigator.platform === 'MacIntel' && (navigator.maxTouchPoints > 1 || 'ontouchend' in document));
const Sidebar = ({ selectedMenuItem, onMenuItemClick }) => {
  if (isMobileDevice) {
    return null; // 行動裝置不顯示 Sidebar
  }

  return (
    <div className="sidebar">
      <h2>
        <img src="/Ｍ+MeetLogo.png" alt="Logo" />
        M+ Meet App Config
      </h2>
      <ul>
        <li className={selectedMenuItem === 'meetVersion' ? 'active' : ''} onClick={() => onMenuItemClick('meetVersion')}>
          <img src="/functionLogo.png" alt="Meet 版本" /> Meet 版本
        </li>
        <li className={selectedMenuItem === 'appConfig' ? 'active' : ''} onClick={() => onMenuItemClick('appConfig')}>
          <img src="/bar-line-chart.png" alt="App 參數設定" /> App 參數設定
        </li>
        <li className={selectedMenuItem === 'products' ? 'active' : ''} onClick={() => onMenuItemClick('products')}>
          <img src="/Communication.png" alt="voip推播紀錄" /> voip推播紀錄
        </li>
        <li className={selectedMenuItem === 'internalTestUrl' ? 'active' : ''} onClick={() => onMenuItemClick('internalTestUrl')}>
          <img src="/download-image.jpg" alt="內部測試網址" /> 內部測試網址
        </li>
        <li className={selectedMenuItem === 'settings' ? 'active' : ''} onClick={() => onMenuItemClick('settings')}>
          <img src="/settings.png" alt="設置" /> 設置
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
