// src/components/MeetVersion.js
import React, { useEffect, useState } from 'react';
import '../styles/meetVersion.css';
import apiService from '../services/apiService'; // 確保引入 apiService

const MeetVersion = () => {
  const [meetVersions, setMeetVersions] = useState([]);
  const [selectedMeetVersion, setSelectedMeetVersion] = useState(null);
  const [meetVersionData, setMeetVersionData] = useState(null);
  const [newVersion, setNewVersion] = useState('');
  const [showAddVersionFields, setShowAddVersionFields] = useState(false);
  const [variablesDropdownOpen, setVariablesDropdownOpen] = useState(false);
  const [newVariables, setNewVariables] = useState([]);
  const [allVariables, setAllVariables] = useState([]);
  const [versionDropdownOpen, setVersionDropdownOpen] = useState(false);

  useEffect(() => {
    const savedVersionId = localStorage.getItem('selectedMeetVersion');
    
    fetchMeetVersions();
    fetchAllVariables();
    
    if (savedVersionId) {
      handleMeetVersionChange(parseInt(savedVersionId));
    }
  }, []);

  const fetchMeetVersions = async () => {
    const versions = await apiService.fetchMeetVersions();
    setMeetVersions(versions);
  };

  const fetchAllVariables = async () => {
    const variables = await apiService.fetchAllVariables();
    setAllVariables(variables);
  };

  const handleMeetVersionChange = async (versionId) => {
    setSelectedMeetVersion(versionId);
    localStorage.setItem('selectedMeetVersion', versionId); // 記住選擇的版本 ID
    setVersionDropdownOpen(false); // 關閉下拉選單
    const versionData = await apiService.fetchMeetVersionData(versionId);
    setMeetVersionData(versionData);
  };

  const handleAddVersionClick = async () => {
    const existingVersion = meetVersions.find(version => version.version === newVersion);
    if (existingVersion) {
      alert('已經有此版本');
      return;
    }
  
    await apiService.addVersion(newVersion);
    setNewVersion('');
    setShowAddVersionFields(false);
    fetchMeetVersions(); // 重新加載版本清單
    alert('新增版本成功');
  };

  const handleDeleteVersionClick = async (versionId) => {
    const confirmed = window.confirm('你確定要刪除這個版本嗎？');
    if (!confirmed) return;
  
    await apiService.deleteVersion(versionId);
    fetchMeetVersions();
    if (selectedMeetVersion === versionId) {
      setSelectedMeetVersion(null);
      setMeetVersionData(null);
    }
  };

  const handleSelectAll = (filteredVariables) => {
    if (newVariables.length === filteredVariables.length) {
      setNewVariables([]);
    } else {
      setNewVariables(filteredVariables.map(variable => ({ value: variable.id.toString(), label: variable.name })));
    }
  };

  const handleAddVariableClick = async () => {
    if (newVariables.length === 0 || !selectedMeetVersion) return;
    const variableIds = newVariables.map(v => parseInt(v.value));
    await apiService.addVariablesToMeetVersion(selectedMeetVersion, variableIds);
    setNewVariables([]);
    setVariablesDropdownOpen(false); // 關閉下拉選單
    handleMeetVersionChange(selectedMeetVersion); // 重新加載選擇的版本數據
  };

const handleDeleteVariable = async (variableId) => {
  try {
    console.log('Deleting variable:', variableId); // 確認變數 ID
    console.log('Selected Meet Version:', selectedMeetVersion); // 確認選擇的 Meet 版本 ID

    const response = await apiService.deleteVariablesfromMeetVersion(selectedMeetVersion, [variableId]); // 傳入 selectedMeetVersion 和 variableId
    console.log('Delete response:', response); // 檢查 API 響應

    // 更新頁面數據，重新載入過濾後的變數
    const updatedData = await apiService.fetchMeetVersionData(selectedMeetVersion);
    console.log('Updated Meet Version Data:', updatedData); // 確認更新後的數據
    setMeetVersionData(updatedData);

    setNewVariables(newVariables.filter(v => v.value !== variableId.toString())); // 從選擇的變數中移除
  } catch (error) {
    console.error('Failed to delete variable:', error);
  }
};

  

  // const handleDeleteAllVariables = async () => {
  //   const variableIds = meetVersionData.variables.map((variable) => variable.id);
  //   try {
  //     await apiService.deleteVariablesfromMeetVersion(selectedMeetVersion, variableIds);
  //     // 更新界面，重新取得數據
  //     const updatedData = await apiService.fetchMeetVersionData(selectedMeetVersion);
  //     setMeetVersionData(updatedData);
  //   } catch (error) {
  //     console.error('Failed to delete all variables:', error);
  //   }
  // };

  const renderMeetVersionDropdown = () => {
    // 確保在 meetVersions 已載入並且找到選擇的版本後才顯示版本名稱
    const selectedVersion = meetVersions.find(version => version.id === selectedMeetVersion);
  
    return (
      <div className="meet-versions-dropdown">
        <div className="dropdown">
          <button className="dropdown-toggle" onClick={() => setVersionDropdownOpen(!versionDropdownOpen)}>
            {selectedVersion ? selectedVersion.version : '載入中...'}
          </button>
          {versionDropdownOpen && (
            <div className="dropdown-menu">
              {meetVersions
                .filter(version => version.id !== selectedMeetVersion)
                .map(version => (
                  <div key={version.id} className="dropdown-item">
                    <span onClick={() => handleMeetVersionChange(version.id)}>
                      {version.version}
                    </span>
                    <button className="delete-button-inline" onClick={() => handleDeleteVersionClick(version.id)}>
                      <i className="fas fa-trash"></i>
                    </button>
                  </div>
                ))}
            </div>
          )}
        </div>
        <button className="add-version-button" onClick={() => setShowAddVersionFields(!showAddVersionFields)}>+</button>
        {showAddVersionFields && (
          <div className="input-version-fields">
            <input
              type="text"
              placeholder="新增版本"
              value={newVersion}
              onChange={(e) => setNewVersion(e.target.value)}
              style={{ width: '110px' }}
            />
            <button className="add-button" onClick={handleAddVersionClick}>新增版本</button>
          </div>
        )}
      </div>
    );
  };
  

  const renderMeetVersionData = () => {
    if (!meetVersionData) {
      return <p>暫無資料</p>;
    }
  
    return (
      <div className="table-container">
        <table className="config-table">
          <thead>
            <tr>
              <th>名稱</th>
              <th>數值</th>
              <th>類別</th>
              <th>平台</th>
              <th>ID</th>
              <th></th> {/* 添加空白表頭，用於對應刪除按鈕的欄位 */}

            </tr>
          </thead>
          <tbody>
            {meetVersionData.variables.map((variable) => (
              <tr key={variable.id}>
                <td>{variable.name}</td>
                <td>{variable.value}</td>
                <td>{variable.category}</td>
                <td>
                  <ul>
                    {variable.platforms.map((platform) => (
                      <li key={platform.id} className={`platform-${platform.name.toLowerCase()}`}>
                        {platform.name}
                      </li>
                    ))}
                  </ul>
                </td>
                <td>{variable.id}</td>
                <td>
                    <button
                      onClick={() => handleDeleteVariable(variable.id)}
                      className="delete-button-inline"
                    >
                      刪除
                    </button>
                  </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const filteredVariables = allVariables.filter(variable => !meetVersionData?.variables.some(v => v.id === variable.id));

  return (
    <div className="page-content">
            <h1>Meet 版本</h1>
            <div className="meet-versions-row">
              {renderMeetVersionDropdown()}
              <div className="add-variables-container">
                <div className="dropdown">
                  <button className="dropdown-toggle" onClick={() => setVariablesDropdownOpen(!variablesDropdownOpen)}>
                    {newVariables.length > 0 ? `已選擇 ${newVariables.length} 個變數` : '請選擇變數'}
                  </button>
                  {variablesDropdownOpen && (
                    <div className="dropdown-menu">
                      <div className="dropdown-item-1" onClick={() => handleSelectAll(filteredVariables)}>
                        <input type="checkbox" checked={newVariables.length === filteredVariables.length} readOnly /> 全選
                      </div>
                      {filteredVariables.map(variable => (
                        <div key={variable.id} className="dropdown-item-1">
                          <input
                            type="checkbox"
                            value={variable.id}
                            checked={newVariables.some(v => v.value === variable.id.toString())}
                            onChange={(e) => {
                              const selectedOptions = e.target.checked
                                ? [...newVariables, { value: variable.id.toString(), label: variable.name }]
                                : newVariables.filter(v => v.value !== variable.id.toString());
                              setNewVariables(selectedOptions);
                            }}
                          /> {variable.name} ({variable.id})
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <button className="add-button" onClick={handleAddVariableClick}>新增變數</button>
                
              </div>
            </div>
            {renderMeetVersionData()}
          </div>
  );
};

export default MeetVersion;
